import { Component, Input, forwardRef } from '@angular/core';
import { ValueAccessorDirective } from '../form/form-directive/value-accessor.directive';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import {
  IconComponent,
  SkeletonLoaderComponent,
  ValidationErrorsModule,
} from '..';
import { CommonModule } from '@angular/common';
type TextareaSize = 'sm' | 'md' | 'lg';

@Component({
  selector: 'bf-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconComponent,
    SkeletonLoaderComponent,
    ValidationErrorsModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
  standalone: true,
})
export class TextareaComponent<T> extends ValueAccessorDirective<T> {
  @Input() label = '';
  @Input() size: TextareaSize = 'md';
  @Input() placeholder = '';
  @Input() readonly = false;
  @Input() inputId = Math.random().toString(36).substring(7);
  @Input() iconLeft = '';
  @Input() iconRight = '';
  @Input() loading = false;
}
