<div class="bf-tabs-wrapper">
  <div
    #tabsContainer
    class="bf-tabs"
    [ngClass]="variant"
    (keydown.tab)="blur()"
  >
    <div
      class="bf-tab flex items-center"
      *ngFor="let tab of tabs; let i = index"
      [class.selected]="activeIndex === i"
      (click)="selectTab(i)"
      (keydown.enter)="selectTab(i)"
      [id]="tab.id"
      [tabindex]="0"
      [attr.aria-label]="tab.ariaLabel"
    >
      <span [class.text-red-600]="tab.hasError">
        {{ tab.label }}
      </span>

      @if(tab.hasError){
      <bf-icon
        [matTooltip]="'Det finns ett fel under tabben'"
        class="ml-2 text-red-600"
        >error</bf-icon
      >
      }

      <bf-icon-button
        class="bf-tab__close ml-2"
        *ngIf="closeable"
        (click)="tabClosed.emit(i)"
        icon="close"
        variant="secondary"
      ></bf-icon-button>
    </div>
  </div>
  <div class="bf-tab-content" [ngClass]="variant">
    <ng-content></ng-content>
  </div>
</div>
