import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'bf-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
})
export class StepComponent {
  @Input() label = '';
  @Input() stepControl?: FormGroup;

  stepIndex = 1;
  activeIndex = 0;
}
