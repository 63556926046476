<ng-container *ngIf="showAll">
  {{ text }}
  <a class="bf-reveal-show-indicator" (click)="toggleShowAll()">Show less</a>
</ng-container>
<ng-container *ngIf="!showAll">
  {{ text.length > maxLength ? (text | slice : 0 : maxLength) + "..." : text }}
  <a
    class="bf-reveal-show-indicator"
    *ngIf="text.length > maxLength"
    (click)="toggleShowAll()"
    >Show all</a
  >
</ng-container>
