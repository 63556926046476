@if(label){
<button
  class="bf-menu-trigger bg-gray-100 hover:shadow-sm hover:bg-gray-200 rounded-full py-1 px-2"
  [matMenuTriggerFor]="menu"
>
  <div class="flex items-center">
    <div class="mr-2 flex">
      <div class="flex items-center gap-3">
        @if(avatar){
        <img
          alt="Profilbild"
          [src]="avatarBase + '/' + avatar"
          class="bf-menu-trigger__avatar shadow-sm w-8 h-8 rounded-full object-cover -ml-2"
        />
        } @else {
        <div
          class="w-8 h-8 text-gray-700 shadow-sm rounded-full bg-gray-200 flex justify-center items-center -ml-1"
        >
          <bf-icon class="w-8 h-8 -ml-1.5" [size]="32">person</bf-icon>
        </div>
        }
        <!--  -->
        @if(label){
        <div>
          {{ label }}
        </div>
        } @if(showSubLabel){
        <div class="bf-menu-trigger__sublabel">
          {{ subLabel }}
        </div>

        <bf-skeleton-loader height="15px" width="120px" [loading]="!subLabel" />
        }
      </div>
    </div>

    @if(icon){
    <bf-icon>{{ icon }}</bf-icon>
    }
  </div>
</button>
} @if(!label){
<bf-icon-button
  [icon]="icon"
  variant="secondary"
  mat-button
  [matMenuTriggerFor]="menu"
/>
}

<mat-menu #menu="matMenu">
  <ng-content></ng-content>
</mat-menu>
