@if(column?.resolver){
<span>{{ column?.resolver ? column?.resolver?.(element) : "N/A" }}</span>
} @else if(!!column?.customComponent && column?.customComponent?.component){

<ng-container
  *ngComponentOutlet="
    column?.customComponent?.component;
    inputs: generatedInputs
  "
/>
} @else {

<ng-container [ngSwitch]="column?.type">
  <ng-container *ngSwitchCase="TableColumnType.Text || TableColumnType.Empty">
    <span [innerHTML]="resolvedValue | highlightSearch : filterValue"></span>
  </ng-container>
  <ng-container *ngSwitchCase="TableColumnType.Badge">
    <div
      class="bf-table-badge"
      [class.success]="resolvedValue === column?.badge?.successValue"
      [class.error]="resolvedValue === column?.badge?.errorValue"
      [class.warning]="resolvedValue === column?.badge?.warningValue"
      [innerHTML]="resolvedValue | translate"
    ></div>
  </ng-container>
  <ng-container *ngSwitchCase="TableColumnType.Array">
    {{ resolvedValue | json }}
  </ng-container>
  <ng-container *ngSwitchCase="TableColumnType.Actions">
    <div class="flex flex-wrap justify-end gap-2">
      @for(action of column?.actions; track action.tooltip){
      <bf-icon-button
        size="sm"
        [aria-label]="action.tooltip || '' | translate"
        *ngIf="action.hideIf ? !action.hideIf(element) : true"
        variant="secondary"
        (click)="triggerColumnChange($event, element, action)"
        [icon]="action.icon"
      ></bf-icon-button>
      }
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <span
      [innerHTML]="resolvedValue || '-' | highlightSearch : filterValue"
    ></span>
  </ng-container>
</ng-container>
}
