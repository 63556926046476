import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '../button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { environment } from '@env';
import { CdkDragDrop, CdkDropList, CdkDrag } from '@angular/cdk/drag-drop';
import { IPropertyImageForm } from '@shared/models';

@Component({
  selector: 'bf-file-upload',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TranslateModule,
    ReactiveFormsModule,
    CdkDropList,
    CdkDrag,
  ],

  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  fb = new FormBuilder();
  @Input() imagesArr!: IPropertyImageForm;
  @Input() imagesToDelete = this.fb.array<FormControl>([]);
  @Input() multiple = false;
  @Input() loading = false;
  @Input() imageSlug = '';
  @Output() filesAdded = new EventEmitter<File[]>();
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement> | undefined;
  readonly FILE_BASE = environment.imageBase;

  get imageBasePath() {
    return this.FILE_BASE + this.imageSlug;
  }

  triggerFileChange(): void {
    this.fileInput?.nativeElement.click();
  }

  onFileChange(event: Event): void {
    if (!event.target) return;
    const files = (event.target as HTMLInputElement).files;
    this.filesAdded.emit(Array.from(files as FileList));
    if (!files) return;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target) {
          this.imagesArr.push(
            this.fb.group({
              imageUrl: this.fb.control(e.target.result as string),
              order: this.fb.control(this.imagesArr.length + 1),
              id: this.fb.control(-1),
            }) as any
          );
        }
      };
      reader.readAsDataURL(file);
    }
  }

  removeFormArrayItem(index: number): void {
    const id = this.imagesArr.at(index).value.id;
    id !== -1 && this.imagesToDelete.push(this.fb.control(id));
    this.imagesArr.removeAt(index);
    this.updateIndexes();
  }

  drop(event: CdkDragDrop<string[]>) {
    this.moveItemInFormArray(
      this.imagesArr,
      event.previousIndex,
      event.currentIndex
    );

    this.updateIndexes();
  }

  updateIndexes(): void {
    this.imagesArr.controls.forEach((control, index) => {
      control.get('order')?.setValue(index + 1);
    });
  }

  moveItemInFormArray(
    formArray: FormArray,
    fromIndex: number,
    toIndex: number
  ): void {
    const dir = toIndex > fromIndex ? 1 : -1;

    const from = fromIndex;
    const to = toIndex;

    const temp = formArray.at(from);
    for (let i = from; i * dir < to * dir; i = i + dir) {
      const current = formArray.at(i + dir);
      formArray.setControl(i, current);
    }
    formArray.setControl(to, temp);
  }
}
