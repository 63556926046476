<div class="bf-form-control">
  <label
    [for]="inputId"
    *ngIf="label"
    [ngClass]="size"
    [class.required]="isRequired && !readonly"
    [class.readonly]="readonly"
    >{{ label }}</label
  >
  <bf-icon
    [class.icon-right]="iconRight"
    [class.icon-left]="iconLeft"
    *ngIf="iconLeft || iconRight"
    >{{ iconLeft || iconRight }}</bf-icon
  >

  <ng-container *ngIf="!loading; else showLoader">
    <ng-template #text>
      <div class="bf-input no-border">
        {{ control.value || "-" }}
      </div>
    </ng-template>

    <textarea
      *ngIf="!readonly; else text"
      [attr.data-cy]="inputId"
      [name]="label"
      [ngClass]="size"
      [class.icon-left]="iconLeft"
      rows="5"
      [class.invalid]="control.invalid && control.touched && control.dirty"
      [tabIndex]="readonly ? -1 : 0"
      [placeholder]="placeholder"
      [formControl]="control"
      [readonly]="readonly"
      [id]="inputId"
    ></textarea>
  </ng-container>
  <ng-template #showLoader>
    <bf-skeleton-loader width="120px" height="17px"></bf-skeleton-loader>
  </ng-template>

  <bf-validation-errors
    *ngIf="!readonly && control.dirty && control.invalid && control.touched"
    [label]="label"
    [errors]="control.errors"
  ></bf-validation-errors>
</div>
