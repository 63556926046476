import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightSearch',
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: string | number, searchValue: string): string | number {
    if (!searchValue || !value) return value;
    value = value.toString();
    const regex = new RegExp(searchValue, 'gi');
    const match = value.match(regex);
    if (!match) return value;
    return value.replace(regex, `<mark>${match[0]}</mark>`);
  }
}
