<div class="bf-controlled-stepper">
  <div
    (click)="toggleOpenState()"
    class="bf-controlled-stepper__header flex justify-between items-center"
  >
    <h2>{{ stepperTitle }}</h2>
    <div class="flex items-center" *ngIf="hasCalculatedSteps" [@fade]>
      <bf-skeleton-loader
        [loading]="!hasLoadedAllSteps"
        height="20px"
        width="100px"
      >
        <span
          class="bf-controlled-stepper__header-step mr-2"
          *ngIf="hasLoadedAllSteps"
          [@fade]
          >{{ "steps_left" | translate : { count: totalSteps } }}
        </span>
      </bf-skeleton-loader>

      <bf-icon [size]="40" [@animationRotate]="opened ? 'open' : 'close'"
        >expand_more</bf-icon
      >
    </div>
  </div>

  <div
    class="bf-controlled-stepper__content"
    [class.opened]="opened"
    [@animationShowHide]="opened ? 'open' : 'close'"
  >
    <ng-content></ng-content>
  </div>
</div>
