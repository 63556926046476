import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ButtonVariant } from '../models/button-variant.type';
import { ButtonSize } from '../button/button.component';

@Component({
  selector: 'bf-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent implements OnChanges {
  @Input('aria-label') ariaLabel = '';
  @Input() icon = '';
  @Input() variant: ButtonVariant = 'primary';
  @Input() loading = false;
  @Input() disabled = false;
  @Input() size: ButtonSize = 'md';

  sizeMultiplier = 1;
  ngOnChanges({ size }: SimpleChanges): void {
    if (size) {
      this.sizeMultiplier = 1;
      switch (size.currentValue) {
        case 'sm':
          this.sizeMultiplier = 0.9;
          break;
        case 'lg':
          this.sizeMultiplier = 1.1;
          break;
      }
    }
  }
}
