import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuillComponent } from './quill/quill.component';
import { QuillModule as NgxQuillModule } from 'ngx-quill';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [QuillComponent],
  imports: [CommonModule, ReactiveFormsModule, NgxQuillModule.forRoot()],
  exports: [QuillComponent],
})
export class QuillModule {}
