import { Component, Input, OnChanges } from '@angular/core';
import {
  TableColumn,
  TableColumnAction,
  TableColumnType,
} from '../table/table.component';
import { get } from 'lodash';

@Component({
  selector: 'bf-table-column-content',
  templateUrl: './table-column-content.component.html',
  styleUrls: ['./table-column-content.component.scss'],
})
export class TableColumnContentComponent<T> implements OnChanges {
  @Input() column: TableColumn | undefined;
  @Input() element: T | undefined;
  @Input() filterValue = '';
  @Input() resolvedValue: string = '';

  generatedInputs: Record<string, any> = {};

  readonly TableColumnType = TableColumnType;

  ngOnChanges(): void {
    if (this.element && this.column) {
      this.resolvedValue = get(this.element, this.column.prop)?.toString();

      if (this.column.customComponent) {
        this.generatedInputs = this.column.customComponent.inputs(this.element);
      }

      if (
        this.column.type === TableColumnType.Array &&
        Array.isArray(this.resolvedValue) &&
        this.column &&
        this.column.arrayProp
      ) {
        this.resolvedValue = this.resolvedValue
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .map((item) => get(item, this.column!.arrayProp as string))
          .join(', ');
      }
    }
  }

  triggerColumnChange(
    $event: Event,
    element: T | undefined,
    action: TableColumnAction
  ): void {
    $event.stopPropagation();
    action.action(element);
  }
}
