<div class="bf-dialog" [ngClass]="data.size">
  <div class="bf-dialog__header pb-2" [class.no-border]="data.color === 'gray'">
    @if(data.title){
    <h2 class="text-xl" [innerHTML]="data.title | translate"></h2>
    }
    <ng-content select="[dialog-header]"></ng-content>
    <bf-icon-button
      class="bf-dialog-close"
      variant="tertiary"
      size="sm"
      [aria-label]="'close' | translate"
      (click)="closeModal()"
      icon="close"
    />
  </div>
  <div
    class="bf-dialog-content"
    [class.hideFooter]="data.hideFooter"
    [ngClass]="data.color"
  >
    <bf-alert
      class="mb-5"
      *ngIf="data.alertText && data.alertType"
      [type]="data.alertType"
      >{{ data.alertText }}</bf-alert
    >
    <p *ngIf="data.content" [innerHTML]="data.content | translate"></p>
    <ng-content></ng-content>
  </div>
  @if(!data.hideFooter){
  <div class="bf-dialog-footer">
    <ng-content select="[dialog-footer]"></ng-content>
    <bf-button
      *ngIf="data.secondaryText"
      variant="secondary"
      (click)="closeModal()"
      >{{ data.secondaryText | translate }}</bf-button
    >
    @if(data.primaryText){
    <bf-button
      id="dialog-primary"
      [variant]="data.primaryButtonType || 'primary'"
      [loading]="isSubmitting"
      (click)="triggerPrimaryAction()"
      >{{ data.primaryText | translate }}</bf-button
    >
    }
  </div>
  }
</div>
