import { Component, Input, ViewEncapsulation, forwardRef } from '@angular/core';
import { ValueAccessorDirective } from '../../form/form-directive/value-accessor.directive';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'bf-quill',
  templateUrl: './quill.component.html',
  styleUrls: ['./quill.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuillComponent),
      multi: true,
    },
  ],
})
export class QuillComponent<T> extends ValueAccessorDirective<T> {
  @Input() mask = '';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() readonly = false;
  @Input() inputId = Math.random().toString(36).substring(7);
  @Input() iconLeft = '';
  @Input() iconRight = '';
  @Input() loading = false;
  modules = {
    clipboard: {
      matchVisual: false,
    },
    toolbar: [['bold', 'italic' /*, 'underline', 'strike'*/]],
  };

  onContentChanged(): void {
    this.control.markAsDirty();
    this.control.markAsTouched();
  }
}
