import { Component, Input } from '@angular/core';

@Component({
  selector: 'bf-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss'],
})
export class BoxComponent {
  @Input() boxTitle = '';
  @Input() noBorder = false;
  @Input() classes = '';
}
