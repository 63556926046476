<div class="flex justify-between items-center">
  <bf-input
    [placeholder]="'search' | translate"
    iconRight="search"
    [formControl]="filterControl"
  />
  <div class="mb-4">
    <ng-content select="[actions]"></ng-content>
  </div>
</div>
<div class="bf-table-wrapper">
  <table
    class="bf-table"
    multiTemplateDataRows
    mat-table
    matSort
    [dataSource]="dataSource"
    (matSortChange)="sortTableData($event)"
  >
    <ng-container
      *ngFor="let column of tableColumns"
      [matColumnDef]="column.header"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="
          column.header === TableColumnType.Actions || data.length === 0
        "
      >
        {{
          (column.header === TableColumnType.Actions ? "" : column.header)
            | translate
        }}
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        [class.bf-table__actions]="column.header === TableColumnType.Actions"
      >
        <div class="table-header-on-tablet-down">
          {{
            (column.header === TableColumnType.Actions ? "" : column.header)
              | translate
          }}
        </div>
        <bf-table-column-content
          [filterValue]="filterControl.value || ''"
          [column]="column"
          [element]="element"
        ></bf-table-column-content>
      </td>
    </ng-container>

    <ng-container *ngIf="hasExpansionContent" [matColumnDef]="'expansion'">
      <th mat-header-cell *matHeaderCellDef></th>
      <td
        mat-cell
        *matCellDef="let element"
        class="bf-table__expansions-column"
        [class.expanded-row]="expandedElement === element"
      >
        <bf-icon [size]="24">expand_more</bf-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail" *ngIf="hasExpansionContent">
      <td
        mat-cell
        class="bf-table_detailed-column"
        [class.expanded]="element === expandedElement"
        *matCellDef="let element"
        [attr.colspan]="columns.length"
      >
        <div
          class="bf-table__expanded-row"
          [class.expanded]="element === expandedElement"
          [@detailExpand]="
            element === expandedElement ? 'expanded' : 'collapsed'
          "
        >
          <div class="bf-table__expanded-row__content">
            <ng-template
              [ngTemplateOutlet]="expansionContent"
              [ngTemplateOutletContext]="{ $implicit: element }"
            >
            </ng-template>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>

    <tr
      mat-row
      *matRowDef="let element; columns: columns"
      [class.expanded-row]="expandedElement === element"
      [class.selectable-row]="
        rowPressed || hasExpansionContent || tableOptions.selectable
      "
      [tabindex]="rowPressed || hasExpansionContent ? 0 : -1"
      (keydown.enter)="expandRow(element)"
      (keydown.space)="expandRow(element)"
      (click)="expandRow(element)"
    ></tr>

    <ng-container *ngIf="hasExpansionContent">
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="bf-table_detailed-row"
      ></tr>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="columns.length">
        <div
          class="bf-empty-list"
          *ngIf="filterControl.value"
          [innerHTML]="
            'no_data_matches_the_search'
              | translate : { search: filterControl.value }
          "
        ></div>
        <div class="bf-empty-list" *ngIf="!filterControl.value">
          {{ emptyRowLabel || "there_are_no_items" | translate }}
        </div>
      </td>
    </tr>
  </table>

  @if(data.length > 0){

  <mat-paginator
    class="mt-2"
    [pageSize]="25"
    [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Select page for table result"
  />
  }
</div>
