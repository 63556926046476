import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { SelectItem } from '../../select/select/select.component';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'bf-sidebar-list',
  templateUrl: './sidebar-list.component.html',
  styleUrls: ['./sidebar-list.component.scss'],
})
export default class SidebarListComponent<T>
  implements OnChanges, OnInit, OnDestroy
{
  @Input() loading = false;
  @Input() titleLabel = '';
  @Input() icon = '';
  @Input() data: T[] = [];
  @Input() baseLink = '';
  @Input() linkKey = 'id';
  @Input() itemName = 'name';
  @Input() itemSubTitle = '';
  @Input() selectItemLabel = '';
  @Input() emptyLabel = '';
  @Input() selectedItem!: T;
  @Input() imageKey = 'avatarUrl';
  @Input() disableAdd = false;
  @Output() addNew = new EventEmitter<void>();
  @Output() itemSelected = new EventEmitter<T | undefined>();
  router = inject(Router);
  selectFC = new FormControl<T[keyof T] | undefined>(undefined);
  mappedList: SelectItem[] = [];
  private _destroy$ = new Subject<void>();

  get _itemName(): keyof T {
    return this.itemName as keyof T;
  }
  get _linkKey(): keyof T {
    return this.linkKey as keyof T;
  }
  get _itemSubTitle(): keyof T {
    return this.itemSubTitle as keyof T;
  }

  ngOnChanges({ data, selectedItem }: SimpleChanges): void {
    if (data) this.updateSelectedList();
    if (selectedItem) {
      const key = this.selectedItem?.[this._linkKey];
      this.selectFC.patchValue(key);
    }
  }

  ngOnInit(): void {
    this.selectFC.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((val) => this.selectItem(Number(val)));
  }

  updateSelectedList() {
    this.mappedList = this.data.map((data) => {
      return {
        label: data[this._itemName] as string,
        value: data[this.linkKey as keyof T] as string,
        image: this.imageKey ? (data[this.imageKey as keyof T] as string) : '',
      };
    });
  }

  selectItem(item: number) {
    const index = this.data.findIndex((i) => i[this._linkKey] === item);
    if (index >= -1 && this.data[index]) {
      this.itemSelected.emit(this.data[index]);
      this.router.navigate([this.baseLink, this.data[index][this._linkKey]]);
      this.selectedItem = this.data[index];
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
