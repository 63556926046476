<div class="file-upload">
  <div
    class="bf-avatar-wrapper"
    [tabindex]="readonly ? -1 : 0"
    [class.autoSize]="autoSize"
    (click)="!readonly && triggerFileChange()"
  >
    @if(control.value) { @if(control.value.endsWith('.webp')) {
    <img
      [src]="
        (folder === 'avatar' ? avatarBase : companyLogoBase) +
        '/' +
        control.value
      "
      alt="Profilbild"
    />
    } @else {
    <img [src]="control.value" alt="Profilbild" />
    } } @else {
    <bf-icon [size]="128">{{ icon }}</bf-icon>
    }
  </div>
  <input
    #fileInput
    class="sr-only"
    type="file"
    [multiple]="true"
    accept="image/png,image/jpeg,image/jpg"
    (change)="onFileChange($event)"
  />

  @if(!readonly){

  <div class="flex flex-col">
    <bf-button
      size="sm"
      variant="secondary"
      class="file-upload__cta"
      (click)="triggerFileChange()"
      leftIcon="upload"
      >{{ (control.value ? "change" : "upload") | translate }}</bf-button
    >
    @if(control.value) {
    <bf-button
      variant="secondary"
      size="sm"
      class="file-upload__cta mt-2"
      (click)="control.reset('')"
      leftIcon="delete"
      >{{ "delete_image" | translate }}</bf-button
    >
    }
  </div>
  }
</div>
