import { Component, inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom, Observable, Subject, takeUntil } from 'rxjs';
import { ButtonVariant } from '../../button/models/button-variant.type';
import { AlertType } from '@shared/ui/alert/alert/alert.component';

type DialogSize = 'sm' | 'md' | 'lg' | 'xl';

export interface DialogData {
  primaryText?: string;
  primaryIconName?: string;
  primaryButtonType?: ButtonVariant;
  secondaryText?: string;
  secondaryIconName?: string;
  title?: string;
  content?: string;
  size?: DialogSize;
  hideFooter?: boolean;
  alertText?: string;
  alertType?: AlertType;
  color?: 'gray' | 'white';
  enableBackdropClose?: boolean;
  callback?: () => Observable<unknown> | Promise<unknown> | unknown;
}
@Component({
  selector: 'bf-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent<T extends DialogData> implements OnDestroy {
  isSubmitting = false;
  data: T = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef);
  private _destroy$ = new Subject<void>();

  constructor() {
    // Ensure backdrops wont close the modal
    this.dialogRef.disableClose = !this.data.enableBackdropClose;

    this.dialogRef
      .keydownEvents()
      .pipe(takeUntil(this._destroy$))
      .subscribe((event: KeyboardEvent) => {
        if (event.key === 'Escape') this.dialogRef.close(false);
      });

    this.data.size = this.data.size || 'md';
  }

  closeModal(): void {
    this.dialogRef.close(false);
  }

  async triggerPrimaryAction(): Promise<void> {
    try {
      this.isSubmitting = true;
      await this.callAction();
      this.isSubmitting = false;
      this.dialogRef.close(true);
    } catch (err) {
      this.isSubmitting = false;
    }
  }

  async callAction(): Promise<void> {
    if (!this.data.callback) return;
    const cb = this.data.callback();

    if (cb instanceof Observable) {
      await firstValueFrom(cb);
    } else if (cb instanceof Promise) {
      await cb;
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
