import { Directive, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  Validators,
  NgControl,
  FormControlName,
  FormGroupDirective,
  FormControlDirective,
} from '@angular/forms';
import { Subject, startWith, distinctUntilChanged, takeUntil, tap } from 'rxjs';

@Directive({
  selector: '[boValueAccessor]',
})
export class ValueAccessorDirective<T>
  implements OnInit, OnDestroy, ControlValueAccessor
{
  control!: FormControl;
  isRequired = false;

  private _isDisabled = false;
  protected _destroy$ = new Subject<void>();
  private _onTouched!: () => T;

  constructor(@Inject(Injector) private injector: Injector) {}

  ngOnInit(): void {
    this.setComponentControl();
    this.isRequired = this.control.hasValidator(Validators.required);
  }

  writeValue(value: T) {
    if (this.control.value === value) return;
    this.control
      ? this.control.setValue(value)
      : (this.control = new FormControl(value));
  }

  registerOnChange(fn: (val: T | null) => T) {
    this.control.valueChanges
      .pipe(
        startWith(this.control.value),
        distinctUntilChanged(),
        takeUntil(this._destroy$),
        tap((val) => fn(val))
      )
      .subscribe(() => this.control.markAsUntouched());
  }

  registerOnTouched(fn: () => T) {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
  }

  private setComponentControl(): void {
    try {
      const formControl = this.injector.get(NgControl);
      switch (formControl.constructor) {
        case FormControlName:
          this.control = this.injector
            .get(FormGroupDirective)
            .getControl(formControl as FormControlName);
          this.control.markAsPristine();
          break;
        default:
          this.control = (formControl as FormControlDirective)
            .form as FormControl;
          this.control.markAsPristine();
          break;
      }
    } catch (error) {
      this.control = new FormControl();
      this.control.markAsPristine();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
