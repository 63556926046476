<div class="bf-form-control">
  <label *ngIf="label" [class.required]="true">{{ label }}</label>
  <div class="flex items-center" *ngIf="!readonly; else displayInterval">
    <input
      [id]="inputId + '-from'"
      [ngClass]="size"
      type="time"
      [max]="to.value"
      [formControl]="from"
    />
    <span class="d-block mx-2">-</span>
    <input
      [id]="inputId + '-to'"
      type="time"
      [ngClass]="size"
      [min]="from.value"
      [formControl]="to"
    />
  </div>
  <ng-template #displayInterval>
    <span class="small-text"> {{ from.value }} - {{ to.value }} </span>
  </ng-template>
</div>
