import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog/dialog.component';
import { ButtonModule } from '../button/button.module';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';
import { AlertModule } from '../alert/alert.module';

@NgModule({
  declarations: [DialogComponent],
  exports: [DialogComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatDialogModule,
    ButtonModule,
    IconComponent,
    AlertModule,
  ],
})
export class DialogModule {}
