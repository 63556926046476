import { Component, Input } from '@angular/core';

@Component({
  selector: 'bf-reveal',
  templateUrl: './reveal.component.html',
  styleUrls: ['./reveal.component.scss'],
})
export class RevealComponent {
  @Input() text = '';
  @Input() maxLength = 100;
  showAll = false;
  toggleShowAll = () => (this.showAll = !this.showAll);
}
