import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { HighlightSearchPipe } from './pipes/highlight-search.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TableColumnContentComponent } from './table-column-content/table-column-content.component';
import { ButtonModule } from '../button/button.module';
import { IconComponent } from '../icon/icon.component';
import { InputComponent } from '../input/input.component';
import { ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    TableComponent,
    HighlightSearchPipe,
    TableColumnContentComponent,
  ],
  imports: [
    CommonModule,
    IconComponent,
    ButtonModule,
    MatSortModule,
    ReactiveFormsModule,
    InputComponent,
    MatTableModule,
    TranslateModule.forChild(),
    MatPaginatorModule,
  ],
  exports: [TableComponent],
})
export class TableModule {}
