<div *ngIf="control" class="bf-form-control bf-ngx-quill">
  <label
    [for]="inputId"
    *ngIf="label"
    [class.required]="isRequired && !readonly"
    [class.readonly]="readonly"
    >{{ label }}</label
  >
  <quill-editor
    [attr.data-cy]="inputId"
    *ngIf="!readonly; else readonlyTemplate"
    [formControl]="control"
    [id]="inputId"
    (onContentChanged)="onContentChanged()"
    [placeholder]="placeholder"
    [modules]="modules"
  ></quill-editor>
  <ng-template #readonlyTemplate>
    <div
      class="bf-quill-preview bf-input"
      [innerHTML]="control.value || '-'"
    ></div>
  </ng-template>
</div>
