import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { CompanyService } from '@shared/services';
import { debounceTime, distinctUntilChanged, map, of, startWith } from 'rxjs';
import { Observable } from 'rxjs';

export class CompanyValidators {
  static orgNumberValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) return null;
      if (/^\d+$/.test(control.value) === false) return { onlyDigits: true };
      if (control.value.length !== 10 && control.value.length !== 12)
        return { orgNumber: true };
      return null;
    };
  }

  static orgNumberExists(companyService: CompanyService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) return of(null);

      return companyService.doesCompanyExistWithOrgNumber(control.value).pipe(
        startWith(control.value),
        debounceTime(300),
        distinctUntilChanged(),
        map((res: boolean) => (res ? { orgNumberExists: true } : null))
      );
    };
  }
}
