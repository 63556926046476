<button
  class="bf-icon-button {{ size }}"
  [disabled]="loading || disabled"
  [matTooltip]="ariaLabel"
  [ngClass]="variant"
  type="button"
  [attr.aria-label]="ariaLabel"
>
  <bf-icon [size]="28 * sizeMultiplier">{{ icon }}</bf-icon>
</button>
