import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bf-burger',
  templateUrl: './burger.component.html',
  styleUrls: ['./burger.component.scss'],
})
export class BurgerComponent {
  @Input() isOpen = false;
  @Input() color: 'dark' | 'light' = 'dark';
  @Output() clicked = new EventEmitter<void>();
}
