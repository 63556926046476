import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './tabs/tabs.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TabComponent } from './tab/tab.component';
import { ButtonModule } from '../button/button.module';
import { IconComponent } from '../icon/icon.component';

@NgModule({
  declarations: [TabsComponent, TabComponent],
  exports: [TabsComponent, TabComponent],
  imports: [CommonModule, MatTabsModule, IconComponent, ButtonModule],
})
export class TabsModule {}
