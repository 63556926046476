import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FADE } from '@shared';
import { Subject } from 'rxjs';

@Component({
  selector: 'bf-controlled-step',
  templateUrl: './controlled-step.component.html',
  styleUrls: ['./controlled-step.component.scss'],
  animations: [FADE],
})
export class ControlledStepComponent implements OnChanges {
  @Input() stepTitle = '';
  @Input() stepDescription = '';
  @Input() icon = '';
  @Input() stepId = '';
  @Input() actionButtonText = '';
  @Input() complete = false;
  @Input() loading = false;
  @Output() actionButtonClicked = new EventEmitter<void>();

  completeChanged = new Subject<void>();
  loadingChanged = new Subject<void>();

  ngOnChanges({ complete, loading }: SimpleChanges): void {
    if (complete) this.completeChanged.next();
    if (loading) this.loadingChanged.next();
  }
}
