import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationErrorsComponent } from './validation-errors/validation-errors.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ValidationErrorsComponent],
  exports: [ValidationErrorsComponent],
  imports: [CommonModule, TranslateModule.forChild({ extend: true })],
})
export class ValidationErrorsModule {}
