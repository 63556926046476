import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu/menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { ButtonModule } from '../button/button.module';
import { BurgerComponent } from './burger/burger.component';
import { SkeletonLoaderComponent } from '../skeleton-loader/skeleton-loader.component';
import { IconComponent } from '../icon/icon.component';
@NgModule({
  declarations: [MenuComponent, BurgerComponent],
  exports: [MenuComponent, MatMenuModule, BurgerComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    SkeletonLoaderComponent,
    ButtonModule,
    IconComponent,
    MatButtonModule,
  ],
})
export class MenuModule {}
