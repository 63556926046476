import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select/select.component';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { ListModule } from '../list/list.module';
import { ValidationErrorsModule } from '../validation-errors/validation-errors.module';

@NgModule({
  declarations: [SelectComponent],
  exports: [SelectComponent],
  imports: [
    CommonModule,
    ListModule,
    MatSelectModule,
    ReactiveFormsModule,
    ValidationErrorsModule,
  ],
})
export class SelectModule {}
