import { Component, Input } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'bf-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input() label = '';
  @Input() avatar? = '';
  @Input() subLabel = '';
  @Input() icon = '';
  @Input() list: string[] = [];
  @Input() showSubLabel = false;
  readonly avatarBase = environment.avatarBase;
}
