import { Component, Input } from '@angular/core';

export type AlertType = 'success' | 'error' | 'warning' | 'info';

@Component({
  selector: 'bf-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  @Input() title = '';
  @Input() type: AlertType = 'info';
  @Input() icon = 'warning';
}
