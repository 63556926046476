import { FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

type FormItem = FormGroup | FormControl | FormArray;
export function validateForm(form: FormItem): {
  hasErrors: boolean;
} {
  if (form.valid) return { hasErrors: form.invalid };
  if (form instanceof FormControl) {
    form.markAsTouched();
    form.markAsDirty();
    return { hasErrors: form.invalid };
  }

  Object.values((form as FormArray | FormGroup).controls as FormItem[]).forEach(
    (control: FormItem) => {
      control?.markAsTouched();
      control?.markAsDirty();

      if (control instanceof FormControl || !control) return;
      if (control?.controls) validateForm(control);
    }
  );

  return { hasErrors: form.invalid };
}

export function nonNullable(validators: ValidatorFn | ValidatorFn[]) {
  return {
    nonNullable: true,
    validators,
  };
}
