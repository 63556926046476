import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'bf-skeleton-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
})
export class SkeletonLoaderComponent {
  @Input() loading = true;
  @Input() height = '1em';
  @Input() width = '100%';
  @Input() rounded = false;
  @Input() variant: 'light' | 'dark' = 'light';
}
