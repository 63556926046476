<div class="bf-form-control">
  <label
    [for]="inputId"
    *ngIf="label"
    [ngClass]="size"
    [class.required]="isRequired && !readonly"
    [class.readonly]="readonly"
    >{{ label }}</label
  >
  <div class="p-relative">
    <bf-icon
      (click)="picker.open()"
      [class.icon-right]="iconRight"
      [class.icon-left]="iconLeft"
      *ngIf="iconLeft || iconRight"
      >{{ iconLeft || iconRight }}</bf-icon
    >

    <ng-container *ngIf="!loading; else showLoader">
      <ng-template #text>
        <div class="bf-input no-border">
          {{ control.value || "-" }}
        </div>
      </ng-template>

      <input
        (click)="picker.open()"
        [matDatepicker]="picker"
        *ngIf="!readonly; else text"
        [attr.data-cy]="inputId"
        [name]="label"
        [ngClass]="size"
        [class.icon-left]="iconLeft"
        [class.invalid]="control.invalid && control.touched && control.dirty"
        [tabIndex]="readonly ? -1 : 0"
        [placeholder]="placeholder"
        [formControl]="control"
        [readonly]="readonly"
        [id]="inputId"
        [min]="futureOnly ? today : null"
      />
    </ng-container>
  </div>
  <ng-template #showLoader>
    <bf-skeleton-loader width="120px" height="17px"></bf-skeleton-loader>
  </ng-template>

  <bf-validation-errors
    *ngIf="!readonly && control.dirty && control.invalid && control.touched"
    [label]="label"
    [errors]="control.errors"
  ></bf-validation-errors>
</div>

<mat-datepicker #picker></mat-datepicker>
