import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const FADE = trigger('fade', [
  transition('void => *', [
    style({ opacity: 0 }),
    animate(300, style({ opacity: 1 })),
  ]),
]);

export const HEIGHT_ANIMATION = trigger('animationShowHide', [
  state('close', style({ height: '0px', overflow: 'hidden' })),
  state('open', style({ height: '*', overflow: 'hidden' })),
  transition('open <=> close', animate('200ms linear')),
]);

export const WIDTH_ANIMATION = trigger('animationWidthShowHide', [
  state('close', style({ width: '0px', overflow: 'hidden' })),
  state('open', style({ width: '*', overflow: 'hidden' })),
  transition('close <=> open', animate('200ms linear')),
]);

export const ROTATE_180 = trigger('animationRotate', [
  state('close', style({ transform: 'rotate(0)' })),
  state('open', style({ transform: 'rotate(-180deg)' })),
  transition('open <=> close', animate('200ms linear')),
]);
