<div class="bf-form-control">
  @if(label){

  <label
    [for]="inputId"
    [ngClass]="size"
    [class.required]="isRequired && !readonly"
    [class.readonly]="readonly"
    >{{ label }}</label
  >
  }
  <mat-select
    *ngIf="!readonly; else readOnlySection"
    [id]="inputId"
    [attr.cy]="inputId"
    class="bf-input"
    [formControl]="control"
    [class.invalid]="control.invalid && control.dirty && control.touched"
    autoActiveFirstOption
    [ariaReadOnly]="readonly"
    [multiple]="multiple"
  >
    <mat-option
      [id]="inputId + '-' + i"
      *ngFor="let option of list; let i = index"
      [value]="option.value"
    >
      {{ option.label }}
    </mat-option>
  </mat-select>
  <ng-template #readOnlySection>
    <ng-container *ngIf="selectedItems().length > 0; else noItems">
      <bf-list size="sm" *ngIf="variant !== 'comma'">
        <li *ngFor="let item of selectedItems(); let i = index">
          {{ item }}
        </li>
      </bf-list>
      <div class="bf-readonly" *ngIf="variant === 'comma'">
        {{ selectedItems().join(", ") }}
      </div>
    </ng-container>
    <ng-template #noItems> - </ng-template>
  </ng-template>

  <bf-validation-errors
    *ngIf="!readonly && control.dirty && control.invalid && control.touched"
    [label]="label"
    [errors]="control.errors"
  ></bf-validation-errors>
</div>
