<article
  class="bf-box rounded-md"
  [class.no-border]="noBorder"
  [ngClass]="classes"
>
  <div
    [class.has-title]="boxTitle"
    class="bf-box__header flex items-center justify-between"
  >
    @if(boxTitle){
    <h2 class="text-xl">
      {{ boxTitle }}
    </h2>
    }
    <ng-content select="[box-actions]"></ng-content>
  </div>
  <ng-content></ng-content>
</article>
