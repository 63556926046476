import {
  AfterViewInit,
  Component,
  ContentChildren,
  Input,
  QueryList,
} from '@angular/core';
import { StepComponent } from '../step/step.component';
import { validateForm } from '../../form/functions/form.functions';

@Component({
  selector: 'bf-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements AfterViewInit {
  @Input() isLinear = true;
  @Input() activeIndex = 0;
  @ContentChildren(StepComponent) steps!: QueryList<StepComponent>;

  get currentStep(): StepComponent | undefined {
    return this.steps.get(this.activeIndex);
  }

  ngAfterViewInit(): void {
    this.steps.forEach((step, index) => {
      step.stepIndex = index;
    });
  }

  jumpTo(index: number) {
    this._updateStepIndex(index);
  }

  stepBack(): void {
    this._updateStepIndex(this.activeIndex - 1);
  }

  stepNext(): void {
    if (!this.isValidStep()) return;
    this._updateStepIndex(this.activeIndex + 1);
  }

  private _updateStepIndex(index: number): void {
    this.activeIndex = index;
    this.steps.forEach((step) => {
      step.activeIndex = index;
    });
  }

  isValidStep(): boolean {
    if (!this.currentStep || !this.currentStep.stepControl) return true;
    return !validateForm(this.currentStep.stepControl).hasErrors;
  }
}
