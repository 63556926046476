@if(!loading){
<ng-content></ng-content>
} @else {
<div
  [ngClass]="variant"
  [class.rounded]="rounded"
  [style.width]="width"
  [style.height]="height"
  class="bf-skeleton"
></div>
}
