import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'bf-validation-errors',
  templateUrl: './validation-errors.component.html',
  styleUrls: ['./validation-errors.component.scss'],
})
export class ValidationErrorsComponent implements OnChanges {
  @Input() errors: ValidationErrors | null = {};
  @Input() label = '';
  @Input() customErrorMessages: Record<string, string> = {};
  errorMessages: Record<string, string> = {
    required: 'required',
    email: 'invalid_email',
    invalid_email: 'invalid_email',
    minlength: 'min_length',
    onlyDigits: 'only_digits',
    orgNumber: 'invalid_org_number',
    emailExists: 'email_exists',
    orgNumberExists: 'company_is_already_registered',
    overlapping: 'overlapping',
    performerNotWorking: 'the_performer_is_not_working_during',
    passwordRequirements: 'password_requirements',
    minimumOnePerformerIsRequired: 'minimum_one_performer_is_required',
  };

  ngOnChanges({ customErrorMessages }: SimpleChanges): void {
    if (customErrorMessages) {
      this.errorMessages = {
        ...this.errorMessages,
        ...(customErrorMessages.currentValue || {}),
      };
    }
  }
}
