import { Component, Input } from '@angular/core';

@Component({
  selector: 'bf-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent {
  @Input('aria-label') ariaLabel = '';
  @Input({ required: true }) label = '';
  @Input() labelKey = '';
  @Input() disabled = false;
  @Input() isActive = false;
  @Input() hasError = false;
  @Input() id = '';
}
