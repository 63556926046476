import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorDirective } from '../../form/form-directive/value-accessor.directive';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'bf-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent
  extends ValueAccessorDirective<boolean>
  implements OnChanges, OnInit
{
  @Input() label = '';
  @Input() indeterminate = false;
  @Input() readonly = false;
  @Input() checkboxId = '';
  @Output() readonly changed = new EventEmitter<boolean>();
  @HostBinding('class.readonly') isReadonly = this.readonly;

  override ngOnInit(): void {
    super.ngOnInit();
    this.control.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this.changed.emit(value);
      });
  }

  ngOnChanges({ readonly }: SimpleChanges) {
    if (readonly) {
      this.isReadonly = readonly.currentValue;
    }
  }
}
