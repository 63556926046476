<div class="bf-form-control">
  @if(label) {
  <label
    [for]="inputId"
    [class.required]="isRequired && !readonly"
    [class.readonly]="readonly"
    >{{ label }}</label
  >
  } @if(iconLeft || iconRight) {
  <bf-icon [class.icon-right]="iconRight" [class.icon-left]="iconLeft">{{
    iconLeft || iconRight
  }}</bf-icon>
  } @if(readonly){
  <div class="bf-input no-border">
    {{ control.value || "-" }}
  </div>
  }

  <input
    class="bf-input"
    #autocompleteElement
    [style.display]="readonly ? 'none' : 'block'"
    [attr.data-cy]="inputId"
    [class.invalid]="control.invalid"
    (blur)="onBlur()"
    [formControl]="addressControl"
    [name]="label"
    [class.icon-left]="iconLeft"
    [tabIndex]="readonly ? -1 : 0"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [id]="inputId"
    [class.invalid]="
      addressControl.invalid && addressControl.touched && addressControl.dirty
    "
  />

  <div class="row mt-5">
    <div class="col-4">
      <bf-input
        [inputId]="inputId + '-zip-code'"
        [label]="'zip_code' | translate"
        [formControl]="zipCodeControl"
        [readonly]="readonly"
      />
    </div>
    <div class="col-8">
      <bf-input
        [inputId]="inputId + '-city'"
        [label]="'city' | translate"
        [formControl]="cityControl"
        [readonly]="readonly"
      />
    </div>
    <div class="col-4">
      <bf-input
        [inputId]="inputId + '-country'"
        [label]="'country' | translate"
        [formControl]="countryControl"
        [readonly]="readonly"
      />
    </div>
    <div class="col-8">
      <bf-input
        [inputId]="inputId + '-county'"
        [label]="'county' | translate"
        [formControl]="countyControl"
        [readonly]="readonly"
      />
    </div>
  </div>

  <bf-validation-errors
    *ngIf="!readonly && control.dirty && control.invalid && control.touched"
    [label]="label"
    [errors]="control.errors"
  ></bf-validation-errors>
</div>
