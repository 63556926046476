<header class="show-on-md-down w100">
  <div class="flex justify-between items-center mt-3">
    <bf-select
      size="sm"
      [list]="mappedList"
      [formControl]="selectFC"
    ></bf-select>
    <bf-button
      *ngIf="selectItemLabel && !disableAdd"
      class="ml-2 mb-4 shrink-0"
      (click)="addNew.emit()"
      icon="add"
      >{{ selectItemLabel | translate }}</bf-button
    >
  </div>
</header>

<div class="flex h100 w100 hide-on-md-down">
  <aside>
    <header class="flex justify-between items-center mb-2">
      <h2 class="bf-sidebar-list__header heading-2 mt-0 mb-0 flex items-center">
        <bf-icon [size]="28" class="mr-1">{{ icon }}</bf-icon>
        {{ titleLabel | translate }}
      </h2>
      <bf-icon-button
        *ngIf="selectItemLabel && !disableAdd"
        (click)="addNew.emit()"
        size="sm"
        icon="add"
        [aria-label]="selectItemLabel | translate"
      ></bf-icon-button>
    </header>

    <ul class="bf-sidebar-list">
      <ng-container *ngIf="loading">
        <li *ngFor="let item of [1, 2, 3]">
          <a class="flex items-center justify-between">
            <bf-skeleton-loader
              variant="dark"
              height="20px"
              width="180px"
            ></bf-skeleton-loader>
            <bf-icon>chevron_right</bf-icon>
          </a>
        </li>
      </ng-container>
      <ng-container *ngIf="!loading">
        <li *ngFor="let item of data">
          <a
            class="flex items-center justify-between"
            (click)="itemSelected.emit(item)"
            [routerLink]="['/', baseLink, item[_linkKey]]"
            routerLinkActive="active"
          >
            <div class="flex items-center w100">
              <div class="grow-1">
                <h2 class="mt-0 mb-0 bf-sidebar-list__title">
                  {{ item[_itemName] }}
                </h2>
                <div class="bf-sidebar-list__sub-title">
                  {{ item[_itemSubTitle] }}
                </div>
              </div>

              <bf-icon>chevron_right</bf-icon>
            </div>
          </a>
        </li>
        <li *ngIf="data.length === 0">
          <div class="bf-empty-list">
            {{ emptyLabel | translate }}

            <bf-button
              [fullWidth]="true"
              class="mt-5"
              (click)="addNew.emit()"
              >{{ selectItemLabel | translate }}</bf-button
            >
          </div>
        </li>
      </ng-container>
    </ul>
  </aside>

  <section class="bf-sidebar-content">
    <ng-content></ng-content>
  </section>
</div>
