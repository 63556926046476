import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { UserService } from '@shared/services';
import { debounceTime, distinctUntilChanged, map, of, startWith } from 'rxjs';
import { Observable } from 'rxjs';

export class UserValidators {
  static emailExists(
    userService: UserService,
    defaultEmail = ''
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || control.value === defaultEmail) return of(null);

      return userService.emailExists(control.value).pipe(
        startWith(control.value),
        debounceTime(300),
        distinctUntilChanged(),
        map((res: boolean) => (res ? { emailExists: true } : null))
      );
    };
  }
}
