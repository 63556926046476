<div [ngClass]="type" class="bf-alert flex" role="alert" aria-live="polite">
  <bf-icon aria-hidden="true" [size]="32">{{ icon }}</bf-icon>
  <div>
    @if(title){
    <p class="bf-alert__title">
      {{ title }}
    </p>
    }
    <div class="bf-alert__content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
