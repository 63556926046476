declare var google: any;
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { ValueAccessorDirective } from '../form/form-directive/value-accessor.directive';
import { ValidationErrorsModule } from '../validation-errors/validation-errors.module';
import { SkeletonLoaderComponent } from '../skeleton-loader/skeleton-loader.component';
import { ButtonModule } from '../button/button.module';
import { of, delay, takeUntil } from 'rxjs';
import { IconComponent } from '../icon/icon.component';
import { InputComponent } from '../input/input.component';
interface IGoogleMapsPlaces {
  address_components: IGoogleMapsPlace[];
  vicinity: string;
  geometry: {
    location: {
      lat: () => number;
      lng: () => number;
    };
  };
}
interface IGoogleMapsPlace {
  types:
    | 'street_number'
    | 'route'
    | 'country'
    | 'postal_code'
    | 'postal_town'
    | 'administrative_area_level_1'
    | 'sublocality';
  long_name: string;
}
@Component({
  selector: 'bf-autocomplete',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ValidationErrorsModule,
    SkeletonLoaderComponent,
    TranslateModule,
    ButtonModule,
    IconComponent,
    InputComponent,
  ],
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
})
export class AutocompleteComponent<T>
  extends ValueAccessorDirective<T>
  implements OnInit, AfterViewInit
{
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input({ required: true }) form!: FormGroup;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() readonly = false;
  @Input() inputId = Math.random().toString(36).substring(7);
  @Input() iconLeft = '';
  @Input() iconRight = '';
  @Input() loading = false;
  @Output() onSelect = new EventEmitter<IGoogleMapsPlaces>();

  addressBase = {
    address: '',
    city: '',
    zipCode: '',
    street_number: '',
    lng: 0,
    county: '',
    municipality: '',
    sublocality: '',
    lat: 0,
    vicinity: '',
    country: 'Sverige',
  };

  googleMapsSearch = structuredClone(this.addressBase);

  readableAddress = '';

  get addressControl() {
    return this.form.get('address') as FormControl;
  }
  get zipCodeControl() {
    return this.form.get('zipCode') as FormControl;
  }
  get cityControl() {
    return this.form.get('city') as FormControl;
  }
  get countryControl() {
    return this.form.get('country') as FormControl;
  }
  get countyControl() {
    return this.form.get('county') as FormControl;
  }
  get municipalityControl() {
    return this.form.get('municipality') as FormControl;
  }
  get sublocalityControl() {
    return this.form.get('sublocality') as FormControl;
  }

  @ViewChild('autocompleteElement') autocompleteER:
    | ElementRef<HTMLInputElement>
    | undefined;

  override ngOnInit() {
    super.ngOnInit();
    this.isRequired = true;
    this.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.mapContentToAutoComplete();
    });
    this.mapContentToAutoComplete();
  }

  ngAfterViewInit(): void {
    this.initAutocomplete();
  }

  private mapContentToAutoComplete() {
    this.readableAddress = this.form.value.address;
    this.googleMapsSearch.address = this.form.value.address;
    this.googleMapsSearch.city = this.form.value.city;
    this.googleMapsSearch.zipCode = this.form.value.zipCode;
    this.googleMapsSearch.lng = this.form.value.lng;
    this.googleMapsSearch.lat = this.form.value.lat;
    this.googleMapsSearch.country = this.form.value.country;
    this.googleMapsSearch.county = this.form.value.county;
    this.googleMapsSearch.vicinity = this.form.value.vicinity;
    this.googleMapsSearch.municipality = this.form.value.municipality;
    this.googleMapsSearch.sublocality = this.form.value.sublocality;
  }

  private initAutocomplete() {
    if (!this.autocompleteER) return;
    const autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteER.nativeElement,
      {
        componentRestrictions: { country: 'SE' },
        types: ['street_address'],
      }
    );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place: any = autocomplete.getPlace();
      this.getAddress(place);
    });
  }

  onBlur(): void {
    this.control.markAsDirty();
    this.control.markAsTouched();

    this.autocompleteER?.nativeElement.value
      ? this.control.setErrors(null)
      : this.control.setErrors({ required: true });
  }

  getAddress(place: IGoogleMapsPlaces) {
    place.address_components.forEach((component) => {
      if (component.types.includes('street_number')) {
        this.googleMapsSearch.street_number = component.long_name;
      } else if (component.types.includes('route')) {
        this.googleMapsSearch.address = component.long_name;
      } else if (component.types.includes('country')) {
        this.googleMapsSearch.country = component.long_name;
      } else if (component.types.includes('postal_code')) {
        this.googleMapsSearch.zipCode = component.long_name;
      } else if (component.types.includes('postal_town')) {
        this.googleMapsSearch.city = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        this.googleMapsSearch.county = component.long_name;
      } else if (component.types.includes('locality')) {
        this.googleMapsSearch.municipality = component.long_name;
      } else if (component.types.includes('sublocality')) {
        this.googleMapsSearch.sublocality = component.long_name;
      }
    });

    this.googleMapsSearch.vicinity = place.vicinity;
    this.googleMapsSearch.lat = place.geometry.location.lat();
    this.googleMapsSearch.lng = place.geometry.location.lng();
    this.readableAddress = `${this.googleMapsSearch.address} ${this.googleMapsSearch.street_number},`;

    this.form.patchValue({
      address: `${this.readableAddress}`.replace(',', ''),
      city: this.googleMapsSearch.city,
      zipCode: this.googleMapsSearch.zipCode,
      lng: this.googleMapsSearch.lng,
      lat: this.googleMapsSearch.lat,
      country: this.googleMapsSearch.country,
      county: this.googleMapsSearch.county,
      vicinity: this.googleMapsSearch.vicinity,
      sublocality: this.googleMapsSearch.sublocality,
      municipality: this.googleMapsSearch.municipality,
    });

    this.onSelect.emit(this.googleMapsSearch as any);
  }

  reset(): void {
    const prevAddress = `${this.readableAddress} ${this.googleMapsSearch.zipCode} ${this.googleMapsSearch.city}`;
    this.googleMapsSearch = structuredClone(this.addressBase);
    of(true)
      .pipe(delay(100))
      .subscribe(() => {
        if (this.autocompleteER) {
          this.autocompleteER.nativeElement.value = prevAddress;
          this.initAutocomplete();
        }
      });
  }
}
