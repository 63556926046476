import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorDirective } from '../../form/form-directive/value-accessor.directive';

type InputSize = 'sm' | 'md' | 'lg';

@Component({
  selector: 'bf-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent<T> extends ValueAccessorDirective<T> {
  @Input() label = '';
  @Input() size: InputSize = 'md';
  @Input() placeholder = '';
  @Input() readonly = false;
  @Input() inputId = Math.random().toString(36).substring(7);
  @Input() iconLeft = '';
  @Input() iconRight = '';
  @Input() loading = false;
  @Input() futureOnly = false;

  today = new Date();
}
