import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ButtonVariant } from '../models/button-variant.type';

export type ButtonSize = 'sm' | 'md' | 'lg';

@Component({
  selector: 'bf-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnChanges {
  @Input() type: 'button' | 'submit' = 'button';
  @Input() variant: ButtonVariant = 'primary';
  @Input() loading = false;
  @Input() leftIcon = '';
  @Input() rightIcon = '';
  @Input() disabled = false;
  @Input() fullWidth = false;
  @Input() size: ButtonSize = 'md';
  @Input() showSubActions = false;
  @HostBinding('class.disabled') isDisabled = false;

  ngOnChanges({ disabled, loading }: SimpleChanges): void {
    if (disabled || loading)
      this.isDisabled = disabled?.currentValue || loading?.currentValue;
  }
}
