import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { ControlledStepComponent } from '../controlled-step/controlled-step.component';
import { Subject, takeUntil } from 'rxjs';
import { FADE, HEIGHT_ANIMATION, ROTATE_180 } from '@shared';

@Component({
  selector: 'bf-controlled-stepper',
  templateUrl: './controlled-stepper.component.html',
  styleUrls: ['./controlled-stepper.component.scss'],
  animations: [HEIGHT_ANIMATION, ROTATE_180, FADE],
})
export class ControlledStepperComponent implements AfterViewInit, OnDestroy {
  @Input() stepperTitle = '';
  @Input() opened = false;
  @ContentChildren(ControlledStepComponent)
  steps!: QueryList<ControlledStepComponent>;
  totalSteps = 0;
  hasCalculatedSteps = false;
  hasLoadedAllSteps = false;

  private destroy$ = new Subject<void>();

  toggleOpenState = () => (this.opened = !this.opened);

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.steps.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this._calculateSteps();
    });
    this.steps.forEach((steps) => {
      steps.completeChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this._calculateSteps();
      });
      steps.loadingChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.hasLoadedAllSteps = Array.from(this.steps).every(
          (step) => !step.loading
        );
      });
    });
    this._calculateSteps();
  }

  private _calculateSteps() {
    this.totalSteps = this.steps.reduce((prev, curr) => {
      return prev + (curr.complete ? 0 : 1);
    }, 0);
    this.hasCalculatedSteps = true;
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
