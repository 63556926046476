import { Component, Input, forwardRef } from '@angular/core';
import { ValueAccessorDirective } from '../form/form-directive/value-accessor.directive';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { FormDirectiveModule } from '@shared/ui/form/form-directive/form-directive.module';
import { IconComponent } from '@shared/ui/icon/icon.component';
import { SkeletonLoaderComponent } from '@shared/ui/skeleton-loader/skeleton-loader.component';
import { ValidationErrorsModule } from '@shared/ui/validation-errors/validation-errors.module';

type InputType = 'search' | 'tel' | 'text' | 'number' | 'email' | 'password';
type InputSize = 'sm' | 'md' | 'lg';
@Component({
  selector: 'bf-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    SkeletonLoaderComponent,
    NgxMaskPipe,
    MatInputModule,
    IconComponent,
    ValidationErrorsModule,
    FormDirectiveModule,
  ],
  standalone: true,
})
export class InputComponent<T> extends ValueAccessorDirective<T> {
  @Input() mask = '';
  @Input() suffix = '';
  @Input() thousandSeparator = '';
  @Input() type: InputType = 'text';
  @Input() label = '';
  @Input() size: InputSize = 'md';
  @Input() placeholder = '';
  @Input() readonly = false;
  @Input() inputId = Math.random().toString(36).substring(7);
  @Input() iconLeft = '';
  @Input() iconRight = '';
  @Input() loading = false;
  @Input() autocomplete?: string;
}
