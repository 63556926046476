import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperComponent } from './stepper/stepper.component';
import { MatStepperModule } from '@angular/material/stepper';
import { StepComponent } from './step/step.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { BoxModule } from '../box/box.module';
import { ButtonModule } from '../button/button.module';
import { ControlledStepperComponent } from './controlled-stepper/controlled-stepper.component';
import { ControlledStepComponent } from './controlled-step/controlled-step.component';
import { TranslateModule } from '@ngx-translate/core';
import { SkeletonLoaderComponent } from '../skeleton-loader/skeleton-loader.component';
import { IconComponent } from '../icon/icon.component';

@NgModule({
  declarations: [
    StepperComponent,
    StepComponent,
    ControlledStepperComponent,
    ControlledStepComponent,
  ],
  exports: [
    StepperComponent,
    ControlledStepComponent,
    ControlledStepperComponent,
    StepComponent,
    MatStepperModule,
    MatIconModule,
  ],
  imports: [
    SkeletonLoaderComponent,
    TranslateModule.forChild(),
    IconComponent,
    CommonModule,
    BoxModule,
    ButtonModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatIconModule,
  ],
})
export class StepperModule {}
