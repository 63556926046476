import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { ValidationErrorsModule } from '../validation-errors/validation-errors.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { SkeletonLoaderComponent } from '../skeleton-loader/skeleton-loader.component';
import { IconComponent } from '../icon/icon.component';
import {
  MatNativeDateModule,
  provideNativeDateAdapter,
} from '@angular/material/core';

@NgModule({
  declarations: [DatepickerComponent],
  exports: [DatepickerComponent, MatDatepickerModule],
  imports: [
    CommonModule,
    ValidationErrorsModule,
    ReactiveFormsModule,
    SkeletonLoaderComponent,
    IconComponent,
    MatNativeDateModule,
    MatDatepickerModule,
  ],
})
export class DatepickerModule {}
