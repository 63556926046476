<mat-checkbox
  [id]="checkboxId"
  [indeterminate]="indeterminate"
  *ngIf="!readonly; else readonlyText"
  [color]="'primary'"
  [formControl]="control"
>
  <ng-content></ng-content>
  {{ label }}
</mat-checkbox>

<ng-template #readonlyText>
  <div class="bf-label">{{ label }}</div>
  <span class="bf-text">
    {{ (control.value ? "yes" : "no") | translate }}
  </span>
</ng-template>
