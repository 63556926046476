import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  QueryList,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { TabComponent } from '../tab/tab.component';
import { Subject, takeUntil } from 'rxjs';

type TabVariant = 'default' | 'contour' | 'filled';

@Component({
  selector: 'bf-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterContentInit, OnDestroy {
  @Input() activeIndex = 0;
  @Input() variant: TabVariant = 'default';
  @Input() closeable = false;
  @Input() selectedLabelKey: string | undefined;
  @Output() tabClosed = new EventEmitter<number>();
  @Output() tabChanged = new EventEmitter<string>();
  @ViewChild('tabsContainer') tabsER: ElementRef | undefined;
  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;
  isFocused = false;
  tabsLength = 0;
  enableNavigation = false;

  private _destroy$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    this.updateTabIndex();

    this.tabs.changes.pipe(takeUntil(this._destroy$)).subscribe((tabs) => {
      if (tabs.length > this.tabsLength) this.activeIndex = tabs.length - 1;

      setTimeout(() => {
        this.tabsLength = tabs.length;
        this.activeIndex = this.tabsLength - 1;
        this.updateTabIndex();
        this.cdr.detectChanges();
        this.enableNavigation = this.isOverflowing();
      }, 100);
    });

    if (this.selectedLabelKey) {
      this.tabs.forEach((tab: TabComponent, index: number) => {
        if (tab.labelKey === this.selectedLabelKey) this.selectTab(index);
      });
    }
  }

  isOverflowing(): boolean {
    return (
      this.tabsER?.nativeElement.scrollWidth >
      this.tabsER?.nativeElement.clientWidth
    );
  }

  selectTab(index: number): void {
    this.activeIndex = index;
    this.updateTabIndex();
  }

  blur(): void {
    document.body.blur();
  }

  private updateTabIndex(): void {
    this.tabs?.forEach((tab: TabComponent, index: number) => {
      tab.isActive = index === this.activeIndex;
    });
    this.tabChanged.emit(this.tabs.get(this.activeIndex)?.labelKey);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
