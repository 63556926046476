<div class="file-upload">
  <input
    #fileInput
    class="sr-only"
    type="file"
    [multiple]="true"
    accept="image/png,image/jpeg,image/jpg"
    (change)="onFileChange($event)"
  />

  <div
    class="grid grid-4 gap-4"
    cdkDropList
    cdkDropListOrientation="mixed"
    (cdkDropListDropped)="drop($event)"
  >
    @for(image of imagesArr.controls; let idx = $index; track idx){
    <div class="item" cdkDrag>
      <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
      <div class="p-relative">
        @if(image.value.imageUrl?.endsWith('.webp')){
        <img
          class="file-upload__img"
          [src]="imageBasePath + '/' + image.value.imageUrl"
        />
        } @else {
        <img class="file-upload__img" [src]="image.value.imageUrl" />
        }
        <bf-icon-button
          size="sm"
          aria-label="Ta bort bild"
          icon="delete"
          (click)="removeFormArrayItem(idx)"
          variant="secondary"
          >{{ "remove" | translate }}</bf-icon-button
        >
      </div>
    </div>
    }
  </div>

  <bf-button
    variant="secondary"
    [class.with-margin-top]="imagesArr.length > 0"
    class="file-upload__cta"
    [loading]="loading"
    (click)="triggerFileChange()"
    leftIcon="upload"
    >{{ "upload" | translate }}</bf-button
  >
</div>
