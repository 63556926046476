<button
  class="bf-button {{ variant }} {{ size }}"
  [class.full-width]="fullWidth"
  [disabled]="loading || disabled"
  [type]="type"
  [class.loading]="loading"
>
  @if(leftIcon){
  <bf-icon [size]="size === 'md' ? 32 : 28" class="left-icon">{{
    leftIcon
  }}</bf-icon>
  } @if(loading){
  <bf-icon class="loading-preloader">progress_activity</bf-icon>
  } @else {
  <ng-content></ng-content>
  } @if(rightIcon){
  <bf-icon class="right-icon">{{ rightIcon }}</bf-icon>
  }
</button>
