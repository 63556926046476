<div
  class="bf-controlled-stepper-step flex items-center justify-between"
  [id]="stepId"
>
  <div class="flex items-center">
    <div class="bf-controlled-stepper-step__icon" [class.complete]="complete">
      <bf-icon [size]="30">{{ icon }}</bf-icon>
    </div>
    <div
      [class.complete]="complete"
      class="bf-controlled-stepper-step__text ml-5 flex flex-direction-column justify-between"
    >
      <h3 class="m-0">{{ stepTitle }}</h3>
      <p class="m-0">{{ stepDescription }}</p>
    </div>
  </div>

  <div class="flex">
    <bf-skeleton-loader
      class="mr-2"
      [loading]="loading"
      height="45px"
      width="45px"
      [rounded]="true"
    ></bf-skeleton-loader>
    <bf-skeleton-loader
      [loading]="loading"
      height="45px"
      width="45px"
      [rounded]="true"
    >
      <div [@fade] *ngIf="!loading">
        <bf-button
          *ngIf="!complete; else completeIcon"
          [attr.data-cy]="stepId + '-add-button'"
          (click)="actionButtonClicked.emit()"
          >{{ actionButtonText }}</bf-button
        >
        <ng-template #completeIcon>
          <div class="flex justify-end">
            <bf-icon-button
              (click)="actionButtonClicked.emit()"
              class="mr-2"
              [attr.data-cy]="stepId + '-add-button'"
              variant="secondary"
              [aria-label]="('edit' | translate) + ' ' + stepTitle"
              icon="edit"
            ></bf-icon-button>
            <bf-icon-button
              variant="success"
              [attr.data-cy]="stepId + '-success-button'"
              [aria-label]="stepTitle + ' ' + ('is_complete' | translate)"
              icon="check"
            ></bf-icon-button>
          </div>
        </ng-template>
      </div>
    </bf-skeleton-loader>
  </div>
</div>
