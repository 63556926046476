import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import SidebarListComponent from './sidebar-list/sidebar-list.component';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '../button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { SelectModule } from '../select/select.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SkeletonLoaderComponent } from '../skeleton-loader/skeleton-loader.component';
import { IconComponent } from '../icon/icon.component';

@NgModule({
  declarations: [SidebarListComponent],
  exports: [SidebarListComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ButtonModule,
    SkeletonLoaderComponent,
    IconComponent,
    SelectModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class SidebarListModule {}
