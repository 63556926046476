<ul class="kfm-validation-error">
  <li
    *ngFor="let error of errors | keyvalue"
    class="kfm-validation-error__item"
  >
    <ng-container [ngSwitch]="error.key">
      <ng-container *ngSwitchCase="'only_digits'">
        {{ errorMessages[error.key] | translate : { fieldName: label } }}
      </ng-container>
      <ng-container *ngSwitchCase="'required'">
        {{ errorMessages[error.key] | translate : { fieldName: label } }}
      </ng-container>
      <ng-container *ngSwitchCase="'minlength'">
        {{
          errorMessages[error.key]
            | translate : { fieldName: label, min: error.value.requiredLength }
        }}
      </ng-container>
      <ng-container *ngSwitchDefault>{{
        errorMessages[error.key] | translate
      }}</ng-container>
    </ng-container>
  </li>
</ul>
