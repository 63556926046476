import { Component, Input } from '@angular/core';

@Component({
  selector: 'bf-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent {
  @Input() variant: 'info' | 'error' | 'success' = 'info';
}
