import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent, DialogData } from './dialog/dialog.component';

interface ComponentType<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new (...args: any[]): T;
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  confirm({
    title = '',
    content = '',
    alertText = '',
    alertType = '',
    primaryText = 'delete',
    secondaryText = 'cancel',
    primaryButtonType = 'primary',
    callback = () => {
      /**/
    },
  }): MatDialogRef<DialogComponent<DialogData>> {
    return this.dialog.open(DialogComponent, {
      data: {
        title,
        content,
        alertText,
        alertType,
        primaryText,
        secondaryText,
        primaryButtonType,
        callback,
      },
    });
  }

  open<T>(comp: ComponentType<T>, data: DialogData): MatDialogRef<T> {
    return this.dialog.open<T>(comp, { data });
  }

  closeAll(): void {
    this.dialog.closeAll();
  }
}
