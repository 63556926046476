import { Component, Input } from '@angular/core';

type ListSize = 'sm' | 'md' | 'lg';
@Component({
  selector: 'bf-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent {
  @Input() size: ListSize = 'md';
  @Input() selectable = false;
}
