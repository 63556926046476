import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  Type,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { CustomMatPaginatorIntl } from './custom-table-paginator.intl';

export class TableOptions {
  searchable = true;
  sortable = true;
  selectable = true;
}

export interface TableColumn {
  header: string;
  prop: string;
  customComponent?: {
    component: any;
    inputs: (row: any) => { [key: string]: any };
  };
  resolver?: (row: any) => any;
  type?: TableColumnType;
  arrayProp?: string;
  actions?: TableColumnAction[];
  badge?: TableColumnBadge;
}

export interface TableColumnBadge {
  type: 'success' | 'warning' | 'danger';
  successValue?: any;
  warningValue?: any;
  errorValue?: any;
}

export interface TableColumnAction {
  icon: string;
  tooltip?: string;
  action: (row: any) => void;
  hideIf?: (row: any) => boolean;
}

export enum TableColumnType {
  Empty = '',
  Text = 'text',
  Link = 'link',
  Array = 'array',
  Badge = 'badge',
  Actions = 'actions',
}

@Component({
  selector: 'bf-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', overflow: 'visible' })),
      transition(
        'collapsed <=> expanded',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
  ],
})
export class TableComponent<T> implements OnDestroy, OnChanges {
  @Input() data: T[] = [];
  @Input() tableColumns: TableColumn[] = [];
  @Input() tableOptions: TableOptions = new TableOptions();
  @Input() emptyRowLabel = '';
  @Output() rowPressed = new EventEmitter<T>();
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ContentChild(TemplateRef) expansionContent!: TemplateRef<{ $implicit: T }>;
  filterControl = new FormControl('');
  expandedElement: T | undefined;
  columns: string[] = [];
  dataSource!: MatTableDataSource<T>;
  hasExpansionContent = true;
  readonly TableColumnType = TableColumnType;

  filterSub: Subscription | undefined;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { data, tableColumns } = changes;
    if (tableColumns) {
      this.columns = tableColumns.currentValue.map(
        (tc: TableColumn) => tc.header
      );
    }
    if (data) {
      this.dataSource = new MatTableDataSource(data.currentValue);
      this.dataSource.paginator = this.paginator;
      data.currentValue.length === 0
        ? this.filterControl.disable()
        : this.filterControl.enable();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.hasExpansionContent = !!this.expansionContent;

    this.filterSub = this.filterControl.valueChanges.subscribe((val) =>
      this.filterTableData(val || '')
    );

    if (this.hasExpansionContent) this.columns.push('expansion');
    this.cdr.detectChanges();
  }

  expandRow(rad: T): void {
    this.rowPressed?.emit(rad);
    if (!this.hasExpansionContent) return;
    this.expandedElement = this.expandedElement === rad ? undefined : rad;
  }

  sortTableData(sort: Sort): void {
    const data = this.data.slice();

    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a: T, b: T) => {
      const aValue = a[sort.active as keyof T];
      const bValue = b[sort.active as keyof T];
      return (aValue < bValue ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
    });

    this.data = this.dataSource.data;
  }

  filterTableData(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.paginator && this.dataSource.paginator.firstPage();
  }

  ngOnDestroy(): void {
    this.filterSub?.unsubscribe();
  }
}
