import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  forwardRef,
  signal,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorDirective } from '../../form/form-directive/value-accessor.directive';
import { takeUntil } from 'rxjs';
type InputSize = 'sm' | 'md' | 'lg';

export interface SelectItem {
  label: string;
  value: string | number;
  image?: string;
}

@Component({
  selector: 'bf-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent<T extends SelectItem>
  extends ValueAccessorDirective<T[keyof T]>
  implements OnInit, OnChanges
{
  @Input() searchable = false;
  @Input() variant: 'comma' | 'default' = 'default';
  @Input() list: T[] = [];
  @Input() size: InputSize = 'md';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() readonly = false;
  @Input() multiple = false;
  @Input() inputId = Math.random().toString(36).substring(7);
  selectedItems = signal<string[]>(['']);

  getImageByItem(item: string): string {
    return this.list.find((i) => i.label === item)?.image || '';
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.control.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this._updateSelectedItems());

    this._updateSelectedItems();
  }

  ngOnChanges({ list }: SimpleChanges): void {
    list && this._updateSelectedItems();
  }

  private _updateSelectedItems(): void {
    const items = this.list
      .filter(
        (item) =>
          this.control?.value?.includes &&
          this.control.value?.includes(item.value)
      )
      .map((i) => i.label);
    this.selectedItems.set(items);
  }
}
