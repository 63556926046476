<div class="bf-stepper">
  <div class="bf-stepper__header flex justify-between items-center">
    <ng-container
      *ngFor="let step of steps; let index = index; let last = last"
    >
      <button
        type="button"
        (click)="jumpTo(index)"
        class="bf-step-button flex items-centers"
        *ngIf="step.label"
      >
        <div
          [class.active]="activeIndex >= step.stepIndex"
          class="bf-step-step__index mr-3"
        >
          {{ step.stepIndex + 1 }}
        </div>
        {{ step.label }}
      </button>

      <div class="bf-step-separator" *ngIf="!last"></div>
    </ng-container>
  </div>
  <bf-box class="bf-stepper__content">
    <ng-content></ng-content>
  </bf-box>
</div>
<div class="flex mt-3">
  <bf-button
    *ngIf="activeIndex > 0"
    (click)="stepBack()"
    class="mr-5"
    variant="secondary"
    leftIcon="arrow_back"
    >Previous</bf-button
  >
  <bf-button
    *ngIf="activeIndex < steps.length - 1"
    (click)="stepNext()"
    variant="secondary"
    rightIcon="arrow_forward"
    >Next</bf-button
  >
</div>
