import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
type TimepickerSize = 'sm' | 'md' | 'lg';
@Component({
  selector: 'bf-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
})
export class TimepickerComponent {
  @Input() label = '';
  @Input() from = new FormControl<Date | string | null>('');
  @Input() to = new FormControl<Date | string | null>('');
  @Input() readonly = false;
  @Input() size: TimepickerSize = 'md';
  @Input() inputId = '';
}
