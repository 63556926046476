import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ValueAccessorDirective } from '../form/form-directive/value-accessor.directive';
import { ButtonModule } from '../button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';
import { environment } from '@env';

@Component({
  selector: 'bf-avatar',
  standalone: true,
  imports: [ReactiveFormsModule, ButtonModule, TranslateModule, IconComponent],
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AvatarComponent),
      multi: true,
    },
  ],
})
export class AvatarComponent extends ValueAccessorDirective<string> {
  @Input() icon = 'person';
  @Input() folder = 'avatar';
  @Input() readonly = false;
  @Input() autoSize = false;
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement> | undefined;
  readonly avatarBase = environment.avatarBase;
  readonly companyLogoBase = environment.companyLogoBase;

  triggerFileChange(): void {
    this.fileInput?.nativeElement.click();
  }

  onFileChange(event: Event): void {
    if (!event.target) return;
    const files = (event.target as HTMLInputElement).files;
    if (!files) return;
    // File to base 64
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target) {
        this.control.setValue(e.target.result as string);
      }
    };
    reader.readAsDataURL(files[0]);
  }
}
