import { AbstractControl, ValidatorFn } from '@angular/forms';

export function passwordValidatornFn(): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.value) return null;
    const hasUpperCase = /[A-Z]/.test(control.value);
    const hasLowerCase = /[a-z]/.test(control.value);
    const hasNumber = /\d/.test(control.value);

    if (hasUpperCase && hasLowerCase && hasNumber) return null;
    return { passwordRequirements: true };
  };
}
